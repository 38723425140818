import React, { useContext, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Ellipsis, SpinLoading,  ErrorBlock } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import IssuedToolPopup from './IssuedToolPopup';
import { matchesPermission } from '../utils/permission-helpers';

const IssuedTools = styled(List)`
	width: 100%;
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function IssuedToolsList(props) {
	const {
		issuedTools,
		onReturn,
		isWithdraw,
		saving,
		onWithdraw,
		loading
	} = props;
	const globalState = useContext(store).state;
	const [showPopover, setShowPopover] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const history = useHistory();
	const { userSelf } = globalState;

	async function onToolWithdraw(data) {
		await onWithdraw(data);
		setShowPopover(false);
	}

	async function onToolReturn(id) {
		await onReturn(id);
		setShowPopover(false);
	}

	function getList() {
		if (!issuedTools.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title={(isWithdraw ? 'No tools found' : 'No tools issued to you')}
				description="Try new search?"
				status='empty'
			/>;
		}

		return <IssuedTools>
			{issuedTools.map(tool => (
				<List.Item
					key={tool.id}
					title={tool.name || tool.part_nr || tool.serial }
					description={tool.issued_to ? `Issued to: ${tool.issued_to_name}` : ''}
					onClick={() => {
						if (!(isWithdraw && tool.issued_to) && matchesPermission(userSelf, ['withdraw_tools', 'issue_tools'])) {
							setSelectedItem(tool);
							setShowPopover(true);
						}
					}}
					clickable={!(isWithdraw && tool.issued_to) && matchesPermission(userSelf, ['withdraw_tools', 'issue_tools'])}
				>
					{tool.marking || '-'}
				</List.Item>
			))}
		</IssuedTools>;
	}


	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getList()}
			<IssuedToolPopup
				visible={showPopover}
				selectedItem={selectedItem}
				loading={saving}
				isWithdraw={isWithdraw}
				onReturn={onToolReturn}
				onWithdraw={onToolWithdraw}
				onClose={() => setShowPopover(false)}
			/>
		</Fragment>
	);
}

export default IssuedToolsList;
