import React, { useContext, useEffect, useState, Fragment } from 'react';
import { NavBar, Popup, SearchBar } from 'antd-mobile';
import { CloseOutline, ScanCodeOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';
import { getList } from '../api';
import ScanPopup from './ScanPopup';
import IssuedToolsList from './IssuedToolsList';
import { serialize, useDebounce } from '../utils/helpers';

const ToolsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const SearchNav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
	.adm-nav-bar-left,
	.adm-nav-bar-right {
		flex: 0;
	}
	.adm-nav-bar-title {
		padding: 0px 12px 0px 0px;
	}
`;

function WithdrawToolsPopup(props) {
	const {
		visible,
		onClose,
		onWithdraw,
		saving
	} = props;
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState('');
	const [tools, setTools] = useState([]);
	const globalState = useContext(store);
	const [showScanPopup, setShowScanPopup] = useState(false);
	const { userSelf } = globalState;

	const debouncedValue = useDebounce(searchValue, 800);

	const fetchTools = async (value, silent) => {
		if (!value || value.length < 3) {
			setTools([]);
			return;
		}

		if (!silent) {
			setLoading(true);
		}

		try {
			const toolsData = await getList('tools', `?${serialize({
				keyword: value,
				status: ['not_due', 'near_due']
			})}`);
			setTools(toolsData);
		} catch (e) {
			setLoading(false);
		}

		setLoading(false);
	};


	useEffect(() => {
		fetchTools(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		setSearchValue('');
		setTools([]);
	}, [visible]);

	async function onWithdrawClick(data) {
		await onWithdraw(data);
		fetchTools(searchValue, true);
	}

	function onScan(value) {
		setSearchValue(value);
		setShowScanPopup(false);
	}

	return (
		<Fragment>
			<ScanPopup onClose={() => setShowScanPopup(false)} onScan={onScan} visible={showScanPopup} />
			<Popup
				position='right'
				visible={visible}
				destroyOnClose={true}
				bodyStyle={{ width: '100%' }}
				onClose={onClose}
			>
				<ToolsWrapper>
					<Nav backArrow={<CloseOutline />} onBack={onClose} back={''}>
						Withdraw tools
					</Nav>
					<SearchNav back={null} right={<ScanCodeOutline onClick={() => setShowScanPopup(true)} fontSize={24} shape="rectangular" />}>
						<SearchBar
							value={searchValue}
							onChange={val => setSearchValue(val)}
							loading={true}
							clearable={true}
							placeholder="Search tools (min 3 letters)"
						/>
					</SearchNav>
					<ContentWrapper>
						<IssuedToolsList
							saving={saving}
							isWithdraw={true}
							loading={loading}
							onWithdraw={onWithdrawClick}
							issuedTools={tools}
						/>
					</ContentWrapper>
				</ToolsWrapper>
			</Popup>
		</Fragment>
	);
}

export default WithdrawToolsPopup;
