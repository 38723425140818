import React, { useContext, useEffect, useState } from 'react';
import { Popup, Button, List } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';

const PopupContent = styled(List)`
	padding: 12px;
	margin-top: 12px;

	.adm-list-body {
		border-top: none;
	}
`;

function IssuedToolPopup(props) {
	const {
		selectedItem,
		visible,
		initialValue,
		onClose,
		onReturn,
		onWithdraw,
		loading
	} = props;
	const [value, setValue] = useState(initialValue || 0);
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	function getIssuedTo(tool) {
		if (tool.issued_to) {
			return tool.issued_to_name || '-';
		}

		return null;
	}

	useEffect(() => {
		setValue(initialValue || 1);
	}, [selectedItem, visible, initialValue]);

	function onSaveClick() {
		onWithdraw({
			id: selectedItem.id,
			issued_to: userSelf.id
		});
	}

	return (
		<Popup
			visible={visible}
			showCloseButton={true}
			onClose={onClose}
			onMaskClick={onClose}
			bodyStyle={{
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
				minHeight: '20vh'
			}}
		>
			<PopupContent>
				<List.Item
					key={selectedItem.id}
					clickable={false}
					title={selectedItem.name || selectedItem.part_nr || selectedItem.serial }
					description={selectedItem.issued_to ? `Issued to: ${getIssuedTo(selectedItem)}` : ''}
				>
					{selectedItem.marking || '-'}
				</List.Item>
				{!selectedItem.issued_to ? <Button
					style={{ marginTop: 12 }}
					onClick={onSaveClick}
					color="success"
					loading={loading}
					block
				>
					{'Withdraw'}
				</Button> : <Button
					style={{ marginTop: 12 }}
					onClick={() => onReturn(selectedItem.id)}
					color="danger"
					loading={loading}
					block
				>
					Return
				</Button>}
			</PopupContent>
		</Popup>
	);
}

export default IssuedToolPopup;
