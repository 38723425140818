import React, { useState, useContext, useEffect } from 'react';
import { FloatingBubble, InfiniteScroll, NavBar, SearchBar, Toast } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { AddOutline, ScanCodeOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { editTool, getIssuedTools } from '../api';
import { store } from '../utils/store';
import ScanPopup from '../components/ScanPopup';
import IssuedToolsList from '../components/IssuedToolsList';
import { serialize, useDebounce } from '../utils/helpers';
import { matchesPermission } from '../utils/permission-helpers';
import WithdrawToolsPopup from '../components/WithrdawToolsPopup';

const IssuedToolsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	padding: 12px 0px;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
	.adm-nav-bar-left,
	.adm-nav-bar-right {
		flex: 0;
	}
	.adm-nav-bar-title {
		padding: 0px 12px 0px 0px;
	}
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function IssuedTools() {
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const history = useHistory();
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showScanPopup, setShowScanPopup] = useState(false);
	const [err, setErr] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [issuedTools, setIssuedTools] = useState([]);
	const [hasMore, setHasMore] = useState('');
	const [page, setPage] = useState(1);
	const [showAddPopup, setShowAddPopup] = useState(false);

	const debouncedValue = useDebounce(searchValue, 800);

	async function fetchIssuedTools({
		newPage,
		silent,
		clear
	}) {
		if (!silent) {
			setLoading(true);
		}
		try {
			setPage(newPage);
			const data = await getIssuedTools(`?${serialize({
				page: newPage,
				search: debouncedValue,
				issued_to: userSelf.id
			})}`);

			const newList = [...(clear ? [] : issuedTools), ...data.items];
			setHasMore(newList.length < data.total_count);
			setIssuedTools(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchIssuedTools({
			newPage: 1,
			clear: true
		});
	}, [debouncedValue]);


	async function onWithdrawTool(data) {
		setSaving(true);
		try {
			await editTool(data.id, {
				issued_to: data.issued_to
			});

			setSaving(false);

			await fetchIssuedTools({
				newPage: 1,
				silent: false,
				clear: true
			});

			Toast.show({
				icon: 'success',
				content: 'Tool withrdrawn'
			});
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Unable to withdraw the tool. Something went wrong.
				</ToastContent>
			});
			setSaving(false);
		}
	}

	async function onReturnTool(id) {
		setSaving(true);
		try {
			await editTool(id, {
				issued_to: null
			});

			setSaving(false);

			await fetchIssuedTools({
				newPage: 1,
				silent: false,
				clear: true
			});

			Toast.show({
				icon: 'success',
				content: 'Tool returned'
			});
		} catch (e) {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Unable to return the tool. Something went wrong.
				</ToastContent>
			});
			setSaving(false);
		}
	}

	function onScan(value) {
		setSearchValue(value);
		setShowScanPopup(false);
	}

	async function onLoadMore() {
		const newPage = page + 1;
		await fetchIssuedTools({ newPage, silent: true });
	}

	return (
		<IssuedToolsWrapper>
			<Nav back={null} right={<ScanCodeOutline onClick={() => setShowScanPopup(true)} fontSize={24} shape="rectangular" />}>
				<SearchBar value={searchValue} onChange={setSearchValue} placeholder='Search' />
			</Nav>
			{matchesPermission(userSelf, ['issue_tools', 'withdraw_tools']) && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowAddPopup(true)}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<ListWrapper>
				<IssuedToolsList
					loading={loading}
					saving={saving}
					onReturn={onReturnTool}
					issuedTools={issuedTools}
				/>
				<InfiniteScroll loadMore={onLoadMore} hasMore={hasMore} />
			</ListWrapper>
			<ScanPopup onClose={() => setShowScanPopup(false)} onScan={onScan} visible={showScanPopup} />
			<WithdrawToolsPopup
				visible={showAddPopup}
				onWithdraw={onWithdrawTool}
				saving={saving}
				onClose={() => setShowAddPopup(false)}
			/>
		</IssuedToolsWrapper>
	);
}

export default IssuedTools;
