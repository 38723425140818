import axios from 'axios';

export const login = async (payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/auth/login`,
		data: payload
	});

	return data;
};

export const logout = async () => {
	const { data } = await axios.get(`${process.env.API_URL}/auth/logout`, { withCredentials: true });
	return data;
};

export const getUserSelf = async () => {
	const { data } = await axios.get(`${process.env.API_URL}/api/self`, { withCredentials: true });
	return data;
};

export const getWorkOrders = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-orders${query}`, { withCredentials: true });
	return data;
};

export const getWorkOrder = async (id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${id}`, { withCredentials: true });
	return data;
};

export const getWorkOrderStats = async (id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${id}/stats`, { withCredentials: true });
	return data;
};

export const getWorkOrderOperations = async (workOrderId, query) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/work-order-ops/${query}`, { withCredentials: true });
	return data;
};

export const getWorkOrderDefects = async (workOrderId, query) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/defects/${query}`, { withCredentials: true });
	return data;
};

export const getWorkOrderOperation = async (workOrderId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/work-order-op/${id}`, { withCredentials: true });
	return data;
};

export const getWorkOrderOperationStats = async (workOrderId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/work-order-op/${id}/stats`, { withCredentials: true });
	return data;
};

export const updateWorkOrderOperation = async (workOrderId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/work-order/${workOrderId}/work-order-op/${payload.id}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const updateWorkOrderOperationTask = async (workOrderOpId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/work-order-op/${workOrderOpId}/work-order-op-task/${payload.id}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const updateWorkOrderDefect = async (workOrderId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/work-order/${workOrderId}/defect/${payload.id}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const getDefect = async (workOrderId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/defect/${id}`, { withCredentials: true });
	return data;
};

export const getWorkOrderOpTasks = async (workOderOpId) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order-op/${workOderOpId}/work-order-op-tasks`, { withCredentials: true });
	return data;
};

export const getWorkOrderOpTask = async (workOderOpId, id) => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order-op/${workOderOpId}/work-order-op-task/${id}`, { withCredentials: true });
	return data;
};

export const sign = async (payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/sign`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const unsign = async (payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/unsign`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const getMaterialsUsed = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/materials-used${query}`, { withCredentials: true });
	return data;
};

export const getToolsUsed = async (workOrderId, query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/work-order/${workOrderId}/tools-used${query}`, { withCredentials: true });
	return data;
};

export const getList = async (objectsType, query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/list/${objectsType}${query}`, { withCredentials: true });
	return data;
};

export const getIssuedTools = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/tools${query}`, { withCredentials: true });
	return data;
};

export const editMaterialsUsed = async (materialId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/materials-used/${materialId}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const editTool = async (toolId, payload) => {
	const { data } = await axios({
		method: 'put',
		url: `${process.env.API_URL}/api/tool/${toolId}`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const addMaterialsUsed = async (payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/materials-used`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const addToolsUsed = async (workOrderId, payload) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/work-order/${workOrderId}/tools-used`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const removeMaterialsUsed = async (materialId, query = '') => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/materials-used/${materialId}${query}`,
		data: {},
		withCredentials: true
	});

	return data;
};

export const removeToolsUsed = async (workOrderId, toolId) => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/work-order/${workOrderId}/tools-used/${toolId}`,
		data: {},
		withCredentials: true
	});

	return data;
};

export const getFiles = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/files/${query}`, { withCredentials: true });
	return data;
};

export const getNotes = async (query = '') => {
	const { data } = await axios.get(`${process.env.API_URL}/api/notes/${query}`, { withCredentials: true });
	return data;
};

export const uploadFile = async (query, formData) => {
	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/files/${query}`,
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		withCredentials: true
	});

	return data;
};


export const createOrEditNote = async (payload) => {
	if (payload.id) {
		const { data } = await axios({
			method: 'put',
			url: `${process.env.API_URL}/api/note/${payload.id}`,
			data: payload,
			withCredentials: true
		});

		return data;
	}


	const { data } = await axios({
		method: 'post',
		url: `${process.env.API_URL}/api/notes`,
		data: payload,
		withCredentials: true
	});

	return data;
};

export const deleteNote = async (id) => {
	const { data } = await axios({
		method: 'delete',
		url: `${process.env.API_URL}/api/note/${id}/`,
		data: {},
		withCredentials: true
	});

	return data;
};
