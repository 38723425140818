import React, { useState, useContext, useEffect } from 'react';
import { FloatingBubble, Toast, Dialog } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { getToolsUsed, addToolsUsed, removeToolsUsed } from '../api';
import { store } from '../utils/store';
import ToolsList from '../components/ToolsList';
import ToolsPopup from '../components/ToolsPopup';
import { matchesPermission } from '../utils/permission-helpers';

const ToolsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function Tools(props) {
	const {
		workOrderId,
		workOrderOpId,
		defectId
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showAddPopup, setShowAddPopup] = useState(false);
	const [err, setErr] = useState(false);
	const [tools, setTools] = useState([]);

	async function fetchToolsUsed(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getToolsUsed(workOrderId);
			setTools(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (workOrderId) {
			fetchToolsUsed();
		}
	}, [workOrderId]);


	async function addNewToolUsed(data) {
		setSaving(true);
		try {
			await addToolsUsed(workOrderId, {
				tool_id: data.id,
				work_order_op_id: workOrderOpId,
				defect_id: defectId
			}).catch(() => {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Something went wrong, cannot add tool.
					</ToastContent>
				});
			});

			setSaving(false);
			fetchToolsUsed(true);
			Toast.show({
				icon: 'success',
				content: 'Tool added'
			});
		} catch (e) {
			setSaving(false);
		}
	}

	async function onUsedToolDelete(id) {
		setSaving(true);
		try {
			const result = await Dialog.confirm({
				cancelText: 'Cancel',
				confirmText: 'Yes',
				style: { textAlign: 'center' },
				content: 'Do you really want to remove this tool?'
			});

			if (result) {
				await removeToolsUsed(
					workOrderId,
					id
				).catch(() => {
					Toast.show({
						icon: 'fail',
						content: <ToastContent>
							Something went wrong, cannot remove tool.
						</ToastContent>
					});
				});

				fetchToolsUsed(true);
			}

			setSaving(false);
		} catch (e) {
			setSaving(false);
		}
	}

	function filterToolsUsed() {
		if (workOrderOpId) {
			return tools.filter(p => p.work_order_op_id === workOrderOpId);
		}

		if (defectId) {
			return tools.filter(p => p.defect_id === defectId);
		}

		return tools;
	}

	return (
		<ToolsWrapper>
			{matchesPermission(userSelf, 'add_work_order_tools') && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowAddPopup(true)}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<ListWrapper>
				<ToolsList
					onAdd={addNewToolUsed}
					onDelete={onUsedToolDelete}
					saving={saving}
					tools={filterToolsUsed()}
					loading={loading}
				/>
			</ListWrapper>
			<ToolsPopup
				visible={showAddPopup}
				onAdd={addNewToolUsed}
				onDelete={onUsedToolDelete}
				saving={saving}
				onClose={() => setShowAddPopup(false)}
			/>
		</ToolsWrapper>
	);
}

export default Tools;
