import { isArray, isNumber } from 'lodash';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

export const getWorkOrderStatusData = (status) => {
	switch (status) {
	case 'in_preparation':
		return {
			text: 'IN PREPARATION',
			color: '#666666'
		};
	case 'ready_for_work':
		return {
			text: 'READY FOR WORK',
			color: '#108ee9'
		};
	case 'in_progress':
		return {
			text: 'IN PROGRESS',
			color: '#2db7f5'
		};
	case 'done':
		return {
			text: 'DONE',
			color: '#87d068'
		};
	case 'closed':
		return {
			text: 'CLOSED',
			color: '#faad14'
		};
	default:
		return {
			text: 'IN PREPARATION',
			color: '#666666'
		};
	}
};


export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
};


export const serialize = (obj) => {
	const str = [];
	Object.keys(obj).forEach((p) => {
		if (isArray(obj[p]) && !obj[p].length) {
			return;
		}

		if (!isNumber(obj[p]) && !obj[p]) {
			return;
		}

		if (obj.hasOwnProperty(p)) {
			str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
		}
	});
	return str.join('&');
};

export const getSignatureTypes = (userSelf, type, isCrs) => {
	let signatureTypes = get(userSelf, 'active_organization.signature_types') || [];

	if (type) {
		signatureTypes = signatureTypes.filter(s => s.type === type && (isCrs ? s.is_crs : true));
	}

	return signatureTypes;
};

export const getUserAllowedSignatureTypes = (userSelf, type, isCrs) => {
	let userAllowed = get(userSelf, 'active_organization.signature_types') || [];

	if (type) {
		userAllowed = userAllowed.filter(s => s.type === type && (isCrs ? s.is_crs : true));
	}

	if (userSelf.is_superadmin) {
		return userAllowed;
	}

	return userAllowed.filter(st => (get(userSelf, 'permission_group.signature_type_ids') || []).includes(st.id));
};
