import React, { useContext, Fragment, useState } from 'react';
import { List, SpinLoading, ErrorBlock, Ellipsis } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import AddMaterialPopup from './AddMaterialPopup';
import { matchesPermission } from '../utils/permission-helpers';

const Materials = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function MaterialsList(props) {
	const {
		materials,
		loading,
		isAdd,
		onAdd,
		onEdit,
		saving
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [showPopover, setShowPopover] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});


	function getUsedIn(material) {
		if (material.work_order_op_id) {
			return material.work_order_op_title || '-';
		}

		if (material.defect_id) {
			return material.defect_title || '-';
		}

		return null;
	}

	async function onMaterialSave(data) {
		if (isAdd) {
			await onAdd(data);
		} else {
			await onEdit(data);
		}

		setShowPopover(false);
	}

	function getDescription(material) {
		if (isAdd) {
			return `${material.barcode ? `BC: ${material.barcode}` : ''}`;
		}

		return getUsedIn(material) ? <Ellipsis direction='end' rows={1} content={`Used in: ${getUsedIn(material)}`} /> : '';
	}

	function getMaterialsList() {
		if (!materials.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description={isAdd ? 'Try another search term' : ''}
				title={isAdd ? 'No materials found' : 'No materials added'}
				status='empty'
			/>;
		}

		return <Materials>
			{materials.map((material) => {
				return <List.Item
					key={material.id}
					title={`${material.batch ? `${material.batch}` : ''} ${material.part_nr ? `/ ${material.part_nr}` : ''} ${material.serial ? `/ ${material.serial}` : ''}`}
					description={getDescription(material)}
					extra={material.quantity ? `${material.quantity}${material.unit || 'pcs'}` : `${material.amount_used}${material.unit || 'pcs'}`}
					onClick={!matchesPermission(userSelf, 'edit_work_order_materials') ? null : () => {
						if (matchesPermission(userSelf, 'edit_work_order_materials')) {
							setSelectedItem(material);
							setShowPopover(true);
						}
					}}
					clickable={matchesPermission(userSelf, 'edit_work_order_materials')}
				>
					{material.name || '-'}
				</List.Item>;
			})}
		</Materials>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getMaterialsList()}
			<AddMaterialPopup
				visible={showPopover}
				selectedItem={selectedItem}
				isAdd={isAdd}
				max={selectedItem.quantity}
				initialValue={selectedItem.amount_used || 1}
				loading={saving}
				onSave={onMaterialSave}
				onClose={() => setShowPopover(false)}
			/>
		</Fragment>
	);
}

export default MaterialsList;
