import React, { useContext, useEffect, useState, Fragment } from 'react';
import { NavBar, Popup, SearchBar } from 'antd-mobile';
import { CloseOutline, ScanCodeOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';
import { getList } from '../api';
import MaterialsList from './MaterialsList';
import ScanPopup from './ScanPopup';

const MaterialsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const SearchNav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
	.adm-nav-bar-left,
	.adm-nav-bar-right {
		flex: 0;
	}
	.adm-nav-bar-title {
		padding: 0px 12px 0px 0px;
	}
`;

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

function MaterialsPopup(props) {
	const {
		visible,
		onClose,
		onAdd,
		saving
	} = props;
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState('');
	const [materials, setMaterials] = useState([]);
	const globalState = useContext(store);
	const [showScanPopup, setShowScanPopup] = useState(false);
	const { userSelf } = globalState;

	const debouncedValue = useDebounce(searchValue, 800);

	const fetchMaterials = async (value, silent) => {
		if (!value || value.length < 3) {
			setMaterials([]);
			return;
		}

		if (!silent) {
			setLoading(true);
		}

		try {
			const materialsData = await getList('materials', `?has_quantity=true&keyword=${value}`);
			setMaterials(materialsData);
		} catch (e) {
			setLoading(false);
		}

		setLoading(false);
	};


	useEffect(() => {
		fetchMaterials(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		setSearchValue('');
		setMaterials([]);
	}, [visible]);

	async function onAddClick(data) {
		await onAdd(data);
		fetchMaterials(searchValue, true);
	}

	function onScan(value) {
		setSearchValue(value);
		setShowScanPopup(false);
	}

	return (
		<Fragment>
			<ScanPopup onClose={() => setShowScanPopup(false)} onScan={onScan} visible={showScanPopup} />
			<Popup
				position='right'
				visible={visible}
				destroyOnClose={true}
				bodyStyle={{ width: '100%' }}
				onClose={onClose}
			>
				<MaterialsWrapper>
					<Nav backArrow={<CloseOutline />} onBack={onClose} back={''}>
						Add materials
					</Nav>
					<SearchNav back={null} right={<ScanCodeOutline onClick={() => setShowScanPopup(true)} fontSize={24} shape="rectangular" />}>
						<SearchBar
							value={searchValue}
							onChange={val => setSearchValue(val)}
							loading={true}
							clearable={true}
							placeholder="Search materials (min 3 letters)"
						/>
					</SearchNav>
					<ContentWrapper>
						<MaterialsList
							isAdd={true}
							onAdd={onAddClick}
							saving={saving}
							materials={materials}
							loading={loading}
						/>
					</ContentWrapper>
				</MaterialsWrapper>
			</Popup>
		</Fragment>
	);
}

export default MaterialsPopup;
