import React, { useState, useContext, useEffect } from 'react';
import { FloatingBubble, Toast, Dialog, InfiniteScroll } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { editMaterialsUsed, getMaterialsUsed, addMaterialsUsed, removeMaterialsUsed } from '../api';
import { store } from '../utils/store';
import MaterialsList from '../components/MaterialsList';
import MaterialsPopup from '../components/MaterialsPopup';
import { matchesPermission } from '../utils/permission-helpers';
import { serialize } from '../utils/helpers';

const MaterialsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function Materials(props) {
	const {
		workOrderId,
		workOrderOpId,
		defectId
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [showAddPopup, setShowAddPopup] = useState(false);
	const [err, setErr] = useState(false);
	const [materials, setMaterials] = useState([]);
	const [hasMore, setHasMore] = useState('');
	const [page, setPage] = useState(1);

	async function fetchMaterialsUsed(currentPage, silent, clear) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getMaterialsUsed(`?${serialize({
				page: currentPage,
				work_order_id: workOrderId,
				defect_id: defectId,
				work_order_op_id: workOrderOpId
			})}`);
			const newList = [...(clear ? [] : materials), ...data.items];
			setHasMore(newList.length < data.total_count);
			setMaterials(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (workOrderId) {
			fetchMaterialsUsed(page);
		}
	}, [workOrderId]);


	async function addNewMaterialUsed(data) {
		setSaving(true);
		try {
			await addMaterialsUsed({
				part_id: data.id,
				amount_used: data.amount_used,
				work_order_id: workOrderId,
				work_order_op_id: workOrderOpId,
				defect_id: defectId
			}).catch(() => {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Something went wrong, cannot add material.
					</ToastContent>
				});
			});

			setSaving(false);
			setPage(1);
			fetchMaterialsUsed(1, true, true);
			Toast.show({
				icon: 'success',
				content: 'Material(s) added'
			});
		} catch (e) {
			setSaving(false);
		}
	}

	async function onUsedMaterialChange(data) {
		setSaving(true);

		try {
			if (data.amount_used === 0) {
				const result = await Dialog.confirm({
					cancelText: 'Cancel',
					confirmText: 'Yes',
					style: { textAlign: 'center' },
					content: 'Do you really want to remove this material?'
				});

				if (result) {
					await removeMaterialsUsed(data.id, `?${serialize({
						work_order_id: workOrderId
					})}`).catch(() => {
						Toast.show({
							icon: 'fail',
							content: <ToastContent>
								Something went wrong, cannot remove material.
							</ToastContent>
						});
					});
				}
			} else {
				await editMaterialsUsed(
					data.id,
					{
						amount_used: data.amount_used,
						work_order_id: workOrderId
					}
				).catch(() => {
					Toast.show({
						icon: 'fail',
						content: <ToastContent>
							Cannot edit material. Might be out of stock.
						</ToastContent>
					});
				});
			}

			setSaving(false);
			setPage(1);
			fetchMaterialsUsed(1, true, true);
		} catch (e) {
			setSaving(false);
		}
	}

	async function onLoadMore() {
		const newPage = page + 1;
		setPage(newPage);
		await fetchMaterialsUsed(newPage, true);
	}

	return (
		<MaterialsWrapper>
			{matchesPermission(userSelf, 'add_work_order_materials') && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowAddPopup(true)}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<ListWrapper>
				<MaterialsList
					onAdd={addNewMaterialUsed}
					onEdit={onUsedMaterialChange}
					saving={saving}
					materials={materials}
					loading={loading}
				/>
				<InfiniteScroll loadMore={onLoadMore} hasMore={hasMore} />
			</ListWrapper>
			<MaterialsPopup
				visible={showAddPopup}
				onAdd={addNewMaterialUsed}
				onEdit={onUsedMaterialChange}
				saving={saving}
				onClose={() => setShowAddPopup(false)}
			/>
		</MaterialsWrapper>
	);
}

export default Materials;
